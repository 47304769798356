:root {
    --narrow-component-width: 970px;
    --font-size-normal: 14px;
}

@media screen and (max-width: 620px) {
  :root {
      --narrow-component-width: 100%;
  }
}

.container--landing-page a,
.container--battle a {
    color: var(--text-color);
}

.container--landing-page,
.container--battle {
    align-items: center;
    background: var(--main-bg-color);
    color: var(--text-color);
    display: flex;
    flex-direction: column;
  font-family: SometypeMono, sans-serif;
    font-size: var(--font-size-normal);
    gap: var(--x-large-margin);
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.container--landing-page .content-wrapper,
.container--battle .content-wrapper {
    align-items: center;
    background: url('/img/footer.png') repeat-x bottom;
    display: flex;
    flex-direction: column;
    padding-bottom: 200px;
    width: 100%;
}

.container--landing-page img,
.container--battle img {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}

.landing-page__header {
    // max-width: var(--max-width-container);
    position: relative;
    width: 100%;
}

.landing-page__error-banner,
.landing-page__main-nav {
  max-width: var(--max-width-container);
  margin: 0 auto;
}

.landing-page__error-banner {
    font-size: var(--font-size-normal);
    font-weight: bold;
    height: 30px;
    margin-top: -30px;
    position: relative;
    text-align: center;
    transition: all 0.2s ease-out;
}

.landing-page__error-banner--visible {
    margin-top: 0;
}

.landing-page__error-banner-inside {
    align-items: center;
    background: var(--error-bg-color);
    color: var(--text-color);
    display: flex;
    height: 30px;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 30;
}

.landing-page__main-nav {
  position: relative;
}

.landing-page__main-nav {
  padding: var(--large-margin);
}

.landing-page__main-nav-inner,
.landing-page__main-nav {
    align-items: flex-end;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: var(--large-margin);
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.landing-page__main-nav-social,
.landing-page__main-nav-utility {
    align-items: center;
    display: flex;
    gap: var(--medium-margin);
    // z-index: 20;
}

.landing-page__main-nav-utility-socials {
  display: flex;
  gap: var(--medium-margin);
  align-items: center;
}

.main-nav-nav__item-btn {
    // background: url('/img/btn.empty.svg') no-repeat;
    background-size: contain;
    color: var(--text-color);
    cursor: pointer;
    font-size: 80%;
    // height: 32px;
    overflow: hidden;
    padding: 0 var(--small-margin);
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 136px;
}

.main-nav-nav__item-btn--connected {
    text-transform: none;
}

.landing-page__main-nav {
  color: var(--text-color);
  font-family: 'SometypeMono', monospace;
  font-size: var(--font-size-normal);
}

.main-nav-nav__item,
.main-nav-nav__item-btn {
    color: var(--text-color);
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    //z-index: 10; // ensure above clouds
}

.main-nav-nav__item:not(.main-nav-nav__item--connect):hover,
.main-nav-nav__item-btn:hover {
    opacity: 0.8;
}

.main-nav-nav__item--connect {
  background-color: transparent;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  align-items: center;
  border-radius: 99em;
  padding: 0.5em 2em;
  transition: all 0.3s;

  &:hover {
    background-color: #FFF;
    color: #000;
  }
}

@media screen and (max-width: 1130px) {
  .main-nav-nav__item--connect.button {
    padding: 0.5em 0.5em;
  }

}
.main-nav-nav__item.main-nav-nav__item--active {
  color: var(--syndicate-color-water);
  text-decoration: underline;
}

.main-nav-nav__item--disabled {
    opacity: 0.5;
}

.main-nav__group {
  display: flex;
  flex-direction: column;
}

.main-nav__group > .main-nav-nav__item:first-of-type {
  padding-right: 20px;
  position: relative;
}

.main-nav__group > .main-nav-nav__item:first-of-type::after {
  content: ' ▸';
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  top: -1px;
}

.main-nav__group:hover > .main-nav-nav__item:first-of-type::after {
  content: ' ▾';
}

.main-nav-nav__item--updated,
.main-nav__group__items {
  display: none;
  position: absolute;
  gap: var(--small-margin);
  padding-top: var(--medium-margin);
  padding-bottom: var(--medium-margin);
  padding-left: var(--small-margin);
  padding-right: var(--small-margin);
  margin-top: var(--medium-margin);
  flex-direction: column;
  z-index: 100;
}

.main-nav__group:hover .main-nav-nav__item--updated,
.main-nav__group:hover .main-nav__group__items {
  background-color: var(--main-bg-color);
  display: flex;
  min-width: 100px;
}

@media screen and (max-width: 820px) {
  .main-nav__group {
    align-items: center;
    gap: var(--medium-margin);
  }
  .main-nav__group .main-nav-nav__item {
    position: relative;
    padding-top: inherit;
    display: block;
  }
  .main-nav__group > .main-nav-nav__item:first-of-type {
    padding-right: 0;
  }
  .main-nav__group > .main-nav-nav__item:first-of-type::after,
  .main-nav__group:hover > .main-nav-nav__item:first-of-type::after {
    content: '';
  }
  .main-nav__group__items {
    display: flex;
    position: static;
    margin-top: 0;
    padding-top: 0;
    align-items: center;
    gap: var(--medium-margin);
  }
}

.landing-page__h2 {
    font-family: Inter, sans-serif;
    font-size: 230%;
    text-align: center;
}

.landing-page__h3 {
    border: 1px solid var(--text-color);
    border-radius: 50px;
    display: inline;
    font-size: 120%;
    padding: var(--small-margin) var(--medium-margin);
    text-transform: uppercase;
}

.landing-page__h4 {
    font-size: 110%;
    margin: var(--small-margin) 0;
    text-transform: uppercase;
}

.landing-page__note-title {
    text-decoration: underline;
}

.landing-page__section--leaderboard {
    max-width: 1440px;
    width: 100%;

    @media (max-width: 621px) {
        overflow-x: auto;
    }
}

.landing-page__section--leaderboard .landing-page__section-content {
    width: 100%;
}

.landing-page__prompt-mint,
.landing-page__prompt-banner {
  color: var(--syndicate-color-water);
}

.landing-page__main-nav-toggle {
  display: none;
}

.mobile-only {
  display: none;
}

.menu-toggle {
  position: relative;
  top: 0;
  width: 20px;
  height: 13px;
  transition: 0.25s;

  span {
    display: block;
    width: 20px;
    height: 1px;
    background: var(--text-color);
    margin-bottom: 5px;
    transition: 0.25s;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


@media screen and (max-width: 820px) {
  .mobile-only {
    display: block;
  }

  .landing-page__main-nav {
    &.is-active {
      .menu-toggle {
        top: 10px;

        span {
          transform: scaleX(0);
          margin-bottom: 0;

          &:first-child,
          &:last-child {
            position: relative;
          }

          &:first-child {
            top: 0;
            transform: rotate(45deg);
          }

          &:last-child {
            top: -2px;
            transform: rotate(-45deg);
          }
        }
      }

      .landing-page__main-nav-inner {
        left: 0;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .landing-page__main-nav-toggle {
    display: flex;
    position: relative;
    z-index: 12;
  }

  .landing-page__main-nav-inner {
    position: fixed;
    top: 0;
    left: -100%;
    transform: translate3d(-100%, 0, 0);
    width: 100%;
    height: 100vh;
    background-color: #000;
    z-index: 10;
    overflow: hidden;
    flex-direction: column;
    transition: transform 0.3s linear;
    padding: var(--x-large-margin) var(--large-margin) var(--large-margin);
  }

  .landing-page__main-nav-social,
  .landing-page__main-nav-utility {
    flex-direction: column;
  }
}

// --------------------------------------------------------
// Revised
// --------------------------------------------------------
.landing-page {
  background-color: var(--main-bg-color);
  color: var(--text-color);
}

.landing-page .main-logo {
  margin-top: var(--x-large-margin);
}

// --------------------------------------------------------
// Hero Text
// --------------------------------------------------------
.landing-page__hero-text {
  color: #FFF;
  font-size: 18px;
  max-width: 38em;
  padding: 0 32px;
  text-align: center;
  text-transform: uppercase;
}

// --------------------------------------------------------
// Headings
// --------------------------------------------------------
.landing-page__hd-amarante {
  color: #FFF;
  font-family: 'Amarante', cursive;
  font-size: 60px;
  line-height: 1.1;
  text-transform: uppercase;
}

.landing-page__inter {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
}

.landing-page__meditation {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-style: italic;
  opacity: 0.5;
  text-align: center;
}

// --------------------------------------------------------
// Section
// --------------------------------------------------------
.section {
  margin-bottom: var(--space-xl);
  margin-top: var(--space-xl);
}

.section--flush {
  margin-bottom: 0;
  margin-top: 0;
}

.section--top {
  position: relative;
  z-index: 10;
}

.section__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1254px; // restricts width
  padding-left: var(--space-l); // adds padding on viewports less than the max-width
  padding-right: var(--space-l);
  width: 100%;
}

.section__container--narrow {
  max-width: 800px;
}

.section__container--disclaimer {
  padding-bottom: var(--x-large-margin);
  padding-left: var(--space-s);
  padding-right: var(--space-s);
  max-width: calc(430px + (var(--space-s) * 2));
  font-size: 12px;
  opacity: 0.5;
}

// --------------------------------------------------------
// Hero Banner
// --------------------------------------------------------
.hero-banner--landing img {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

// --------------------------------------------------------
// Feature
// --------------------------------------------------------
.feature {
  display: flex;
  flex-direction: row-reverse;
  font-family: 'SometypeMono', monospace;
  font-size: 14px;
  gap: 72px;
  margin-bottom: 72px;
}

.feature a:not([class]) {
  color: #FFF;
  text-decoration: underline;
}

.feature--rev {
  flex-direction: row;
}

.feature--clouds {
  position: relative;
}

.feature__media {
  display: grid;
  place-items: center;
  width: 55%;
}

.feature__content {
  padding-left: 128px;
  padding-right: 0;
  width: 45%;

  @media (max-width: 1100px) {
    padding-left: 64px;
  }
}

.feature--rev .feature__content {
  padding-left: 0;
  padding-right: 128px;

  @media (max-width: 1100px) {
    padding-right: 64px;
  }
}

.feature__content,
.feature--rev .feature__content {
  @media (max-width: 870px) {
    padding: 0;
  }
}

.feature,
.feature--rev {
  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.feature__media,
.feature__content {
  @media (max-width: 760px) {
    width: 100%;
  }
}

.feature__content > * + * {
  margin-bottom: var(--space-xs);
}

.feature__number {
  align-items: center;
  color: #F3FFE6;
  display: inline-flex;
  font-size: 15px;
  line-height: 1;
  position: relative;
  white-space: nowrap;
}

.feature__number::before,
.feature__number::after {
  content: '';
  flex-shrink: 0;
  height: 24px;
  width: 6px;
}

.feature__number::before {
  background: #000 url('/img/pixel.bracket.left.svg') no-repeat 0 0;
  margin-right: 0.5em;
}

.feature__number::after {
  background: #000 url('/img/pixel.bracket.right.svg') no-repeat 0 0;
  margin-left: 0.5em;
}

.feature__heading {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-weight: 700;
}

// --------------------------------------------------------
// Join the Battle Section
// --------------------------------------------------------
.join {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;

  // Margin demonstrate the ancient wisdom
  // of messing with something until it looks right
  margin-top: -15%;

  @media (max-width: 1200px) {
    margin-top: -25%;
  }

  @media (max-width: 960px) {
    margin-top: -35%;
  }

  @media (max-width: 540px) {
    margin-top: -55%;
  }

  @media (max-width: 480px) {
    margin-top: -65%;
  }
}

.join__content,
.join__media {
  grid-area: 1 / 1 / -1 / -1;
}

.join__content {
  padding: 0 15px;
  z-index: 5;
}

.join__media {
  display: grid;
  place-items: center;
  overflow: hidden;
  z-index: 1;
}

.join__media img {
  height: auto;
  max-width: 1450px; // magic number to line up with diamon
}
